// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-jsx": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\src\\templates\\project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-single-jsx": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\src\\templates\\single.jsx" /* webpackChunkName: "component---src-templates-single-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-about-index-mdx": () => import("C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\src\\pages\\about\\index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\wamp64\\www\\github-starters\\starter-gatsbyjs-emma\\.cache\\data.json")

